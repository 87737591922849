// Libraries
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiAcquisitionsPortal = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.ENV_PRICING_ROCKET_API_URL as string,
    prepareHeaders: (headers: Headers) => {
      headers.set(
        'x-api-key',
        process.env.ENV_PRICING_ROCKET_API_KEY as string
      );
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: () => ({}),
  reducerPath: 'apiAcquisitionsPortal',
  tagTypes: ['Polygons', 'MedianZones', 'getSavedIntersections'],
});

export const apiInventoryPortal = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.ENV_INVENTORY_PORTAL_API_URL as string,
    prepareHeaders: (headers: Headers) => {
      headers.set(
        'x-api-key',
        process.env.ENV_INVENTORY_PORTAL_API_KEY as string
      );
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: () => ({}),
  reducerPath: 'apiInventoryPortal',
});

export const apiDocumentsPortal = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.ENV_DOCUMENTS_API_URL as string,
    prepareHeaders: (headers: Headers) => {
      headers.set('x-api-key', process.env.ENV_DOCUMENTS_API_KEY as string);
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: () => ({}),
  reducerPath: 'apiDocumentsPortal',
  tagTypes: ['Tasks'],
});

export const apiSellersPortal = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.ENV_SELLERS_PORTAL_API_URL as string,
    prepareHeaders: (headers: Headers) => {
      headers.set(
        'x-api-key',
        process.env.ENV_SELLERS_PORTAL_API_KEY as string
      );
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: () => ({}),
  reducerPath: 'apiSellersPortal',
});

export const apiPricingPortal = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.ENV_PORTAL_PRICING_API,
    prepareHeaders: headers => {
      headers.set(
        'x-api-key',
        process.env.ENV_PORTAL_PRICING_API_KEY as string
      );
      headers.set('Content-Type', 'application/json');
      headers.set('Env-Origin', process.env.ENV_ORIGIN as string);
      return headers;
    },
  }),
  endpoints: () => ({}),
  reducerPath: 'apiPricingPortal',
});
