import { TaskItem } from '@domains/documents-review/interfaces/interfaces';
import { RootState } from '@store';
import { createSlice } from '@reduxjs/toolkit';

export enum ReviewOption {
  APPROVE = 'approve',
  REJECT = 'reject',
  ABORT = 'aborted',
}

export interface DocumentReviewState {
  selectedOption: ReviewOption | null;
  selectedTask: TaskItem | null;
  showModal: ReviewOption | null;
}

export const initialState: DocumentReviewState = {
  selectedOption: null,
  selectedTask: null,
  showModal: null,
};

export const domainDocumentReviewSlice = createSlice({
  name: 'domainDocumentReview',
  initialState,
  reducers: {
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    setSelectedTask: (state, action) => {
      state.selectedTask = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setDefaultState: () => {
      return initialState;
    },
  },
});

export const {
  setSelectedOption,
  setSelectedTask,
  setShowModal,
  setDefaultState,
} = domainDocumentReviewSlice.actions;

export default domainDocumentReviewSlice.reducer;

// selectors.
export const getSelectedOption = (state: RootState) =>
  state.domainDocumentReview.selectedOption;

export const getRejectForm = (state: RootState) =>
  state.domainDocumentReview.selectedOption === ReviewOption.REJECT;

export const getSelectedTask = (state: RootState) =>
  state.domainDocumentReview.selectedTask;

export const getShowModal = (state: RootState) =>
  state.domainDocumentReview.showModal;
