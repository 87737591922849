export const formatPrice = (price: number | null | undefined): string => {
  if (!price) return 'No tiene';

  const roundedPrice = Math.round(price);
  return new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 0,
  }).format(roundedPrice);
};
