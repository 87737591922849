// libraries.
import { useNavigate } from 'react-router-dom';

// components.
import { ButtonSidebar } from '@shared';
import { IconDocumentTool } from '@assets/icons';

export const ButtonDocumentsTool = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/document-audit/tasks');
  };

  return (
    <ButtonSidebar
      icon={<IconDocumentTool fill="white" />}
      text="Documents Tool"
      onClick={handleClick}
    />
  );
};
