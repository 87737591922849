// libraries.
import { useSelector } from 'react-redux';

// selectors.
import {
  ReviewOption,
  getSelectedOption,
} from '@domains/documents-review/store/slices/document-review-slice';

// components.
import { RejectForm } from './DocumentForms/RejectForm/RejectForm';
import { ReviewContainer } from './DocumentForms/ReviewContainer';
import { ApproveForm } from './DocumentForms/ApproveForm/ApproveForm';

export const DocumentReview = () => {
  const selectedOption = useSelector(getSelectedOption);

  return (
    <div className="d-flex flex-column justify-content-center h-100 gap-3">
      {selectedOption === null && <ReviewContainer />}
      {selectedOption === ReviewOption.APPROVE && <ApproveForm />}
      {selectedOption === ReviewOption.REJECT && <RejectForm />}
    </div>
  );
};
