// libraries.
import { styled } from 'styled-components';
import {
  electricPurple800,
  monserratSemiBold,
  robotoRegular,
  scarpaGray500,
} from '@habitech/shared';

const WrapperPolygonTitle = styled.div`
  font-family: ${monserratSemiBold};
  font-weight: 600;
  font-size: 20px;
  color: ${electricPurple800};
  .subtitle {
    font-family: ${robotoRegular};
    font-size: 12px;
    color: ${scarpaGray500};
    font-weight: 400;
  }
`;

export const DocumentsTitleBar = () => {
  return (
    <WrapperPolygonTitle className="d-flex flex-column align-items-center w-100">
      <div className="d-flex w-100 gap-2 flex-column py-2">
        <div className="d-flex">Revisión de documentos</div>
        <div className="d-flex gap-2 justify-content-between subtitle"></div>
      </div>
    </WrapperPolygonTitle>
  );
};
