// assets.
import hero from '@assets/images/hero.png';
import docGirl from '@assets/images/doc-girl.png';
import agentGirl from '@assets/images/agent-girl.png';

export interface ImgProps {
  className?: string;
  height?: number;
  style?: React.CSSProperties;
  width?: number;
}

export const ImageHero = ({ style, className, width, height }: ImgProps) => (
  <img
    src={hero}
    alt="hero"
    className={className}
    style={style}
    width={width}
    height={height}
  />
);

export const ImageConfused = ({
  style,
  className,
  width,
  height,
}: ImgProps) => (
  <img
    src={docGirl}
    alt="docGirl"
    className={className}
    style={style}
    width={width}
    height={height}
  />
);

export const ImageAgent = ({ style, className, width, height }: ImgProps) => (
  <img
    src={agentGirl}
    alt="agentGirl"
    className={className}
    style={style}
    width={width}
    height={height}
  />
);
