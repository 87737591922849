// libraries.
import { robotoRegular, scarpaGray500 } from '@habitech/shared';

// types.
import { TaskItem } from '@domains/documents-review/interfaces/interfaces';

// assets.
import { ImageAgent } from '@assets/images';

interface Props {
  taskDetail: TaskItem | null;
}

export const ReviewAgentValidation = ({ taskDetail }: Props) => {
  if (!taskDetail) return null;

  return (
    <div className="d-flex flex-column align-items-center gap-3 p-4">
      <ImageAgent height={140} width={80} />
      <p
        style={{
          fontSize: '14px',
          fontFamily: robotoRegular,
          color: scarpaGray500,
        }}
      >
        <p>
          {`Este ejercicio está asignado a ${taskDetail?.agent}. Puedes `}
          <strong style={{ color: 'black' }}>
            quitarle la asignación
          </strong>{' '}
          para continuar con el proceso.
        </p>
      </p>
    </div>
  );
};
