// shared.
import { ButtonLogo, Sidebar } from '@shared';

export const SidebarDocuments = () => {
  return (
    <Sidebar
      upperButtons={
        <>
          <ButtonLogo />
        </>
      }
    />
  );
};
