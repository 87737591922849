// libraries.
import { useDispatch } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';

// slice.
import {
  ReviewOption,
  setShowModal,
} from '@domains/documents-review/store/slices/document-review-slice';
import { reviewOptionDetails } from './utils';
import { WrapperModal } from '@domains/polygon-tool/components/styles';

interface ModalFormProps {
  onSave: () => void;
  option: ReviewOption;
  register?: any;
  show: boolean;
  subtitle?: string;
  disabled?: boolean;
  submitLabel?: string;
}

export const ModalForm = ({
  onSave,
  option,
  register,
  show,
  subtitle,
  disabled,
  submitLabel,
}: ModalFormProps) => {
  const dispatch = useDispatch();

  const { icon, title } = reviewOptionDetails[option] || {
    icon: null,
    title: '',
  };

  const handleClose = () => {
    dispatch(setShowModal(null));
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <WrapperModal>
        <Modal.Header className="d-flex px-1 py-3 border-bottom-0">
          <Modal.Title>
            {icon && <span>{icon}</span>} {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-1 p-0 pb-3">
          {subtitle && <p className="subtitle">{subtitle}</p>}
          {register && (
            <Form.Group controlId="formComment">
              <Form.Control
                as="textarea"
                placeholder="Escribir..."
                rows={3}
                {...register('comment', { shouldValidate: false })}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer className="flex-nowrap p-0 pb-1 border-top-0">
          <Button className="btn-habi-white w-100" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            className="btn-habi w-100"
            disabled={disabled}
            onClick={onSave}
          >
            {submitLabel || 'Guardar'}
          </Button>
        </Modal.Footer>
      </WrapperModal>
    </Modal>
  );
};
