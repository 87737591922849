import { useSelector } from 'react-redux';
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';
import { CountryCode } from '@domains/country';

export type CountryDictionary<T> = Partial<Record<CountryCode, T>>;

export const useCountryDictionary = <T,>(
  dictionary: CountryDictionary<T>
): T | null => {
  const countryCode = useSelector(getCountry) as CountryCode;
  return dictionary[countryCode] || null;
};
