// libraries.
import { useSelector } from 'react-redux';

// selectors.
import { getSelectedTask } from '@domains/documents-review/store/slices/document-review-slice';
import { BusinessType } from '@pages/DocumentAuditPage/modules/DocumentsAuditDashboard/components/DocumentsTable/components/BusinessType/BusinessType';
import { AbortTask } from '../../../../shared/AbortTask/AbortTask';
import { DataSellersByCountry } from './components/DataSellersByCountry';

export const TaskDetail = () => {
  const detailTask = useSelector(getSelectedTask);

  if (!detailTask) return null;

  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex align-item-center gap-2">
        <div className="nid">{detailTask.nid}</div>
        <div>
          <BusinessType businessType={detailTask.business_type} />
        </div>
      </div>
      <div className="d-flex justify-content-between w-100">
        <div className="properties">
          <div>{detailTask.task_type}</div>
          <DataSellersByCountry />
        </div>
        <AbortTask />
      </div>
    </div>
  );
};
