// libraries
import { createGlobalStyle } from 'styled-components';
import monserratRegular from './fonts/Montserrat-Regular.ttf';
import monserratBold from './fonts/Montserrat-Bold.ttf';
import monserratLight from './fonts/Montserrat-Light.ttf';
import monserratSemiBold from './fonts/Montserrat-SemiBold.ttf';
import monserratBlack from './fonts/Montserrat-Black.ttf';
import robotoRegular from './fonts/Roboto-Regular.ttf';
import {
  electricPurple000,
  electricPurple500,
  electricPurple600,
  scarpaGray200,
  scarpaGray50,
} from '@habitech/shared';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Montserrat';
    src: url(${monserratRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat Bold';
    src: url(${monserratBold}) format('truetype');
    font-weight: 700; 
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat Light';
    src: url(${monserratLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat SemiBold';
    src: url(${monserratSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat Black';
    src: url(${monserratBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${robotoRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  html {
    height: 100%;
  }

  body {
    font-family: 'Montserrat', sans-serif; 
    height: 100%;
  }
  p, h1, h2, h3, h4, h5 {
    margin: 0;
  }

  // buttons.
  .btn-habi{
    height: 35px;
    font-size: 14px;
    font-family: 'Roboto';
    src: url(${robotoRegular}) format('truetype');
    background-color: ${electricPurple500};
    border: none;
    border-radius: 8px;
  }
  .btn-habi:hover{
    background-color: ${electricPurple600};
  } 
  .btn-habi:disabled {
    background-color: ${scarpaGray200};
    cursor: not-allowed;
    border: 2px solid ${scarpaGray200};
  }
  .btn-habi-link {
    font-size: 14px;
    font-family: 'Roboto';
    color: rgba(229, 23, 23, 1);
    text-decoration: none;
    width: -webkit-fill-available;
  }
  .btn-habi-link:hover{
    color: rgba(229, 23, 23, 1);
  } 

  .btn-habi-white {
    font-size: 14px;
    height: 35px;
    font-family: 'Roboto';
    src: url(${robotoRegular}) format('truetype');
    background-color: ${electricPurple000};
    color:  ${electricPurple600};
    border: 1px solid ${electricPurple000};
    border-radius: 8px;
  }
  .btn-habi-white:hover {
    color:  ${electricPurple600};
    background-color: rgb(214, 243, 255);
    border: 1px solid rgb(214, 243, 255);
  }
  .delete {
    cursor: pointer;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${scarpaGray50};
    border-radius: 8px;
    background: ${electricPurple000}
  }
`;
