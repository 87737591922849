// slices.
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { apiInventoryPortal } from '@store/apiSlices/apiSlices';

// interfaces.
import { ModelError } from '@store/apiSlices/interfaces/interfaces';

interface DocRolesParams {
  country: string;
  email: string;
}

export enum DocRolesEnum {
  DocumentReviewer = 'document_reviewer',
}

interface DocRolesResponse {
  body: { role: DocRolesEnum };
}

export const docReviewRoles = apiInventoryPortal.injectEndpoints({
  endpoints: builder => ({
    getDocReviewRoles: builder.query({
      query: ({ country, email }: DocRolesParams) => {
        return `/get_role_agent?country=${country}&email=${email}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: 'getDocReviewRoles',
        };
        return modelError;
      },
      transformResponse: (response: DocRolesResponse) => {
        const role = response.body.role;
        return { role };
      },
    }),
  }),
});

export const { useGetDocReviewRolesQuery } = docReviewRoles;
