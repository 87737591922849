export const rejectReasonsCO = [
  { id: '1', label: 'Falta doc de área construida' },
  { id: '2', label: 'Falta doc de garaje' },
  { id: '3', label: 'Falta doc de área construida y garaje' },
  { id: '4', label: 'Faltan docs legales' },
  { id: '5', label: 'Por antigüedad' },
  { id: '6', label: 'No se puedo validar área' },
  { id: '7', label: 'No se puede validar antigüedad' },
  { id: '8', label: 'Documentos incompletos' },
  { id: '9', label: 'Vivienda no habi' },
  { id: '10', label: 'Información errónea de remo en hubspot' },
  { id: '11', label: 'Falta recibo admon' },
];
