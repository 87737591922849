// libraries.
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useEffect } from 'react';
import {
  getCountry,
  getEmail,
} from '@domains/user/store/slices/user-slice/user-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// slice.
import { TaskItem } from '@domains/documents-review/interfaces/interfaces';
import { setSelectedTask } from '@domains/documents-review/store/slices/document-review-slice';
import { useUpdateTaskStatusMutation } from '@domains/documents-review/store/api-slices/update-task-status.endpoints';

// icons.
import { IconDocument, IconSearch } from '@assets/icons';
import { TaskActionsByAgent } from './TaskActionByAgent';

interface TaskActionProps {
  action: string;
  nid: string;
  task: TaskItem;
}

export enum ActionTaskEnum {
  PENDING = 'pending',
  PROGRESS = 'progress',
}

export const TaskAction = ({ action, nid, task }: TaskActionProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const country = useSelector(getCountry);
  const email = useSelector(getEmail);

  const [mutateProgress, { isError, isLoading, isSuccess }] =
    useUpdateTaskStatusMutation();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();

    if (action === ActionTaskEnum.PENDING) {
      const updateTask = {
        id: task.id,
        agent: email,
        country: country,
        status: ActionTaskEnum.PROGRESS,
      };
      mutateProgress(updateTask);
      dispatch(
        setSelectedTask({
          ...task,
          agent: email,
          action: ActionTaskEnum.PROGRESS,
        })
      );
    }
  };

  const handleIfTaskIsInProgress = () => {
    if (action === ActionTaskEnum.PROGRESS) {
      dispatch(setSelectedTask(task));
      navigate(`/document-audit/task/${nid}`);
    }
  };

  useEffect(() => {
    if (isLoading) {
      toast.loading('Iniciando tarea...', { toastId: 'loadingProgressTask' });
    } else {
      toast.dismiss('loadingProgressTask');
    }

    if (isSuccess) {
      navigate(`/document-audit/task/${nid}`);
    } else if (isError) {
      toast.error('Error iniciando esta tarea', {
        toastId: 'errorProgressTask',
      });
    }
  }, [isSuccess, isError, isLoading, navigate, nid]);

  const actions: Record<string, JSX.Element> = {
    Revisar: (
      <Button
        variant="link"
        onClick={handleClick}
        className="d-flex w-100 align-items-center gap-1 justify-content-center text-primary rows"
      >
        <IconSearch /> Revisar
      </Button>
    ),
    pending: (
      <Button
        variant="link"
        disabled={isLoading}
        onClick={handleClick}
        className="d-flex p-1 w-100 align-items-center gap-1 justify-content-center text-primary rows"
      >
        <IconDocument /> Comenzar
      </Button>
    ),
    progress: (
      <TaskActionsByAgent task={task} onViewTask={handleIfTaskIsInProgress} />
    ),
  };

  return actions[action] || null;
};
