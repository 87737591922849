// interface.
import { IRoute } from '@routes/routes';

// types.
import { pathOPCDashboard } from './routes.types';

// components.

export const routesOrchestratorPricingCommitteePage: IRoute[] = [
  {
    id: 'dashboard-opc',
    path: `${pathOPCDashboard}`,
    element: <div>Dashboard de pricing committee</div>,
  },
];
