// libraries.
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

// styles.
import { WrapperInputField } from '@shared/InputField/styles';

interface NumericFieldProps {
  label: string;
  name: string;
  min?: number;
  max?: number;
  placeholder?: string;
  step?: number;
  required?: boolean;
}

export const NumericField = ({
  label,
  name,
  min = 0,
  max = 20,
  step = 1,
  placeholder = '',
  required = false,
}: NumericFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const validationRules = required ? { required: 'Campo requerido' } : {};

  return (
    <WrapperInputField>
      <Form.Group>
        <Form.Label htmlFor={name}>{label}</Form.Label>
        <Form.Control
          id={name}
          type="number"
          {...register(name, validationRules)}
          min={min}
          max={max}
          step={step}
          placeholder={placeholder}
          onKeyDown={e => {
            const value = (e.target as HTMLInputElement).value;
            const isNumber = /^\d$/.test(e.key);
            const isAllowedKey =
              ['Backspace', 'Delete'].includes(e.key) ||
              e.key.includes('Arrow');

            if ((value.length >= 2 || !isNumber) && !isAllowedKey) {
              e.preventDefault();
            }
          }}
        />
      </Form.Group>
      <div className="d-flex justify-content-center">
        {errors[name] && (
          <small className="text-danger my-1 fw-bold">
            {errors[name]?.message as string}
          </small>
        )}
      </div>
    </WrapperInputField>
  );
};
