const commonLabelsMX = {
  invalidDoc: 'Documento no es válido',
};

export const rejectReasonsMX = [
  { id: '1', label: 'Falta documento área construida' },
  { id: '2', label: 'Falta visita operativa (coordenadas, elevador, sin BQ)' },
  { id: '3', label: 'Falta de documento garaje' },
  { id: '4', label: 'Documento no coincide con visita' },
  { id: '5', label: 'Documentos no legibles' },
  {
    id: '6',
    label: `${commonLabelsMX.invalidDoc} (Parámetros de comprobar veracidad)`,
  },
  { id: '7', label: 'Por Antigüedad' },
  { id: '8', label: 'Falta documentación para inmueble menor a 38m2' },
  { id: '9', label: 'Inconsistencia en medidas (remodelaciones)' },
  { id: '10', label: 'Lead sin información en Base de datos' },
  { id: '11', label: 'Rechazo por fuera de zona Habi' },
  {
    id: '12',
    label: 'Rechazo porque registro no coincide con visita operativa',
  },
  { id: '13', label: 'Falta video de visita operativa' },
  { id: '14', label: 'Falta de área de terreno' },
  { id: '15', label: 'Descartado por ampliaciones ilegales' },
  { id: '16', label: 'Segunda Visita' },
  { id: '17', label: 'Sin documentación' },
  { id: '18', label: `${commonLabelsMX.invalidDoc} área construida` },
  { id: '19', label: `${commonLabelsMX.invalidDoc} garaje` },
  { id: '20', label: `${commonLabelsMX.invalidDoc} terreno` },
  { id: '21', label: `${commonLabelsMX.invalidDoc} antigüedad` },
  { id: '22', label: 'Daño estructural 2017' },
  {
    id: '23',
    label: 'Falta documento válido para comprobar área excedente de terreno',
  },
  { id: '24', label: 'Documento no contiene dirección' },
];
