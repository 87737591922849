import { electricPurple000, robotoRegular } from '@habitech/shared';
import { styled } from 'styled-components';

export const WrapperInputField = styled.div`
  font-size: 14px;
  font-family: ${robotoRegular};
  width: 100%;
  .form-control,
  .form-select {
    font-size: 14px;
  }
  .form-label,
  label {
    margin-bottom: 0.2rem;
  }
  .input-group-text {
    font-size: 14px;
    background: ${electricPurple000};
  }
  input[type='number'] {
    appearance: auto;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 1 !important;
  }
  input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
  }
  .multi-dropdown {
    font-size: 14px;
    background: white;
    color: #212529;
    border: 1px solid #dee2e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:focus {
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    }
    &:active {
      background: white;
      color: #212529;
    }
  }
  
  .wrapper-items {
    font-size: 14px;
    height: 300px;
    overflow: scroll;
    .dropdown-item {
      white-space: normal;
      .form-check-input {
        padding: 0;
        width: 15px;
        height: 15px;
       &:checked {
          background-color: rgb(124, 1, 255);
          border-color: rgb(124, 1, 255);
       }
      }
      &:active {
        background: white !important;
        color: #212529 !important;
      }
    }
  }
}
`;
