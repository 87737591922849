// libraries.
import { Badge } from 'react-bootstrap';

// utils.
import { TaskItem } from '@domains/documents-review/interfaces/interfaces';

export const PendingTasks = ({ tasks }: { tasks: TaskItem[] }) => {
  return (
    <div className="wrapper-tasks">
      <div className="d-flex justify-content-between align-items-center p-3">
        <div className="d-flex gap-2 align-items-center">
          <strong className="fw-bold">Tareas</strong>
          <Badge className="tasks">{tasks.length}</Badge>
        </div>
      </div>
    </div>
  );
};
