import { robotoRegular, scarpaGray500 } from '@habitech/shared';
import { styled } from 'styled-components';

export const WrapperTable = styled.div`
  font-family: ${robotoRegular};
  height: inherit;
  overflow: scroll;
  .headers {
    font-size: 14px;
    font-weight: 900;
  }
  table {
    border: 1px solid rgba(243, 243, 243, 1);
  }
  th {
    background: rgba(250, 250, 250, 1);
  }
  .rows {
    font-size: 13px;
  }
  td {
    padding: 8px !important;
  }
  .badge-inmobiliaria {
    font-size: 13px;
    background-color: rgba(204, 229, 221, 1) !important;
    color: #206a5d !important;
    font-weight: 400 !important;
  }

  .badge-comite {
    font-weight: 400 !important;
    font-size: 13px;
    background-color: rgba(230, 239, 253, 1) !important;
    color: #1e3a8a !important;
  }
  .wrapper-tasks {
    font-size: 14px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid rgba(243, 243, 243, 1);
  }
  .tasks {
    background: rgba(243, 243, 243, 1) !important;
    color: ${scarpaGray500};
  }
`;
