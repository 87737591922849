import { Badge } from 'react-bootstrap';

export enum BusinessTypeEnum {
  REAL_ESTATE = 'real_estate',
  COMMITTEE = 'committee',
}

export const getBadgeClass = (businessType: string) => {
  const objectBusinessType = {
    style: '',
    label: '',
  };

  if (businessType === BusinessTypeEnum.REAL_ESTATE) {
    objectBusinessType.style = 'badge-inmobiliaria';
    objectBusinessType.label = 'Inmobiliaria';
    return objectBusinessType;
  }

  if (businessType === BusinessTypeEnum.COMMITTEE) {
    objectBusinessType.style = 'badge-comite';
    objectBusinessType.label = 'Comité';
    return objectBusinessType;
  }

  objectBusinessType.style = 'badge-empty';
  objectBusinessType.label = 'No definido';

  return objectBusinessType;
};

export const BusinessType = ({ businessType }: { businessType: string }) => {
  const { style, label } = getBadgeClass(businessType);

  return <Badge className={style}>{label}</Badge>;
};
