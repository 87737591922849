// libraries.
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

// slices.
import { apiPricingPortal } from '@store/apiSlices/apiSlices';

// interface.
import { ModelError } from '@store/apiSlices/interfaces/interfaces';
import { ITransformResponse, PricingRecords } from '../interfaces/interfaces';

export const leadDetail = apiPricingPortal.injectEndpoints({
  endpoints: builder => ({
    getLeadDetailInfo: builder.query({
      query: ({ country, nid }: { country?: string; nid?: string }) => {
        return `/get_lead?country=${country}&nid=${nid}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: 'getLeadDetailInfo',
        };
        return modelError;
      },
      transformResponse: (response: ITransformResponse): PricingRecords => {
        return response.results[0].pricing_records;
      },
    }),
  }),
});

export const { useGetLeadDetailInfoQuery } = leadDetail;
