// libraries.
import { UpdateTaskData } from '@domains/documents-review/interfaces/interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

// slices.
import { apiDocumentsPortal } from '@store/apiSlices/apiSlices';

// interfaces.
import { ModelError } from '@store/apiSlices/interfaces/interfaces';

// interfaces.
interface UpdateTaskStatusResponse {
  message: string;
  success: boolean;
}

export const updateTaskStatus = apiDocumentsPortal.injectEndpoints({
  endpoints: builder => ({
    updateTaskStatus: builder.mutation({
      query: (taskUpdateData: UpdateTaskData) => ({
        body: taskUpdateData,
        method: 'PATCH',
        url: '/update_task_status',
      }),
      transformErrorResponse: (error: FetchBaseQueryError): ModelError => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: 'updateTaskStatus',
        };
        return modelError;
      },
      transformResponse: (response: UpdateTaskStatusResponse) => {
        return response;
      },
      invalidatesTags: (_, __, arg) => {
        const status = (arg as UpdateTaskData)?.status;
        return status === 'aborted' ? ['Tasks'] : [];
      },
    }),
  }),
});

export const { useUpdateTaskStatusMutation } = updateTaskStatus;
