// libraries.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CountryCode, ICountrySelect } from '@domains/country';
import { RootState } from '@store';
import { RolesPolynatorEnum } from '../../api-slices/roles-polynator.endpoint';
import { DocRolesEnum } from '../../api-slices/roles-documents.endpoints';

export interface StateUserProfile {
  countriesOptions?: ICountrySelect[];
  country?: CountryCode;
  email: string;
  familyName?: string;
  givenName?: string;
  googleId?: string;
  imageUrl?: string;
  name?: string;
  roles?: string[];
}

export const initialState: StateUserProfile = {
  countriesOptions: [],
  country: undefined,
  email: '',
  familyName: '',
  givenName: '',
  googleId: '',
  imageUrl: '',
  name: '',
  roles: [],
};

export const domainUserAuthSlice = createSlice({
  name: 'domainUserAuth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<StateUserProfile>) => {
      return { ...state, ...action.payload };
    },
    setCountriesOptions: (state, action: PayloadAction<ICountrySelect[]>) => {
      state.countriesOptions = action.payload;
    },
    setCountry: (state, action: PayloadAction<CountryCode>) => {
      state.country = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setFamilyName: (state, action: PayloadAction<string>) => {
      state.familyName = action.payload;
    },
    setGivenName: (state, action: PayloadAction<string>) => {
      state.givenName = action.payload;
    },
    setGoogleId: (state, action: PayloadAction<string>) => {
      state.googleId = action.payload;
    },
    setImageUrl: (state, action: PayloadAction<string>) => {
      state.imageUrl = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setRoles: (state, action: PayloadAction<string>) => {
      if (!state.roles) {
        state.roles = [];
      }
      if (!state.roles.includes(action.payload)) {
        state.roles.push(action.payload);
      }
    },
    setDefaultState: () => {
      return initialState;
    },
  },
});

export const {
  setUser,
  setCountriesOptions,
  setCountry,
  setEmail,
  setFamilyName,
  setGivenName,
  setGoogleId,
  setImageUrl,
  setName,
  setRoles,
  setDefaultState,
} = domainUserAuthSlice.actions;

export default domainUserAuthSlice.reducer;

// selectors.
export const getEmail = (state: RootState) => state.domainUserAuth.email;

export const getCountry = (state: RootState) => state.domainUserAuth.country;

export const getRole = (state: RootState) => state.domainUserAuth.roles;

export const getIsRoleIsPolygonCreator = (state: RootState) =>
  state.domainUserAuth.roles?.includes(RolesPolynatorEnum.PolygonCreator) ??
  false;

export const getIsDocumentReviewerRole = (state: RootState) =>
  state.domainUserAuth.roles?.includes(DocRolesEnum.DocumentReviewer) ?? false;
