// interfaces.
import { ReviewOption } from '@domains/documents-review/store/slices/document-review-slice';

// assets.
import { IconApprove, IconReject, IconWarning } from '@assets/icons';

export const reviewOptionDetails = {
  [ReviewOption.APPROVE]: {
    icon: <IconApprove />,
    title: 'Aprobar',
  },
  [ReviewOption.REJECT]: {
    icon: <IconReject />,
    title: 'Rechazar',
  },
  [ReviewOption.ABORT]: {
    icon: <IconWarning />,
    title: 'Abandonar',
  },
};
