// libraries.
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// domain.
import {
  ReviewOption,
  getSelectedOption,
  setSelectedOption,
} from '@domains/documents-review/store/slices/document-review-slice';
import { WrapperReviewForm } from '../../styles';

export const ReviewFormCheck = () => {
  const dispatch = useDispatch();

  const selectedOption = useSelector(getSelectedOption);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedOption(event.target.value as ReviewOption));
  };

  return (
    <WrapperReviewForm className="w-100">
      <Form className="w-100 h-100">
        <Form.Check
          type="radio"
          label="Sí cumple, continuar con la revisión"
          name="documentVerification"
          value={ReviewOption.APPROVE}
          checked={selectedOption === ReviewOption.APPROVE}
          onChange={handleChange}
        />
        <Form.Check
          type="radio"
          label="No cumple, rechazar"
          name="documentVerification"
          value={ReviewOption.REJECT}
          checked={selectedOption === ReviewOption.REJECT}
          onChange={handleChange}
        />
      </Form>
    </WrapperReviewForm>
  );
};
