// libraries.
import { useSelector } from 'react-redux';

// selectors.
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';

// components.
import { Line } from '@shared/styles';
import { ApproveFormCO } from './ApproveFormCO/ApproveFormCO';
import { ApproveFormMX } from './ApproveFormMX/ApproveFormMX';
import { ReviewFormCheck } from '../ReviewFormCheck';

export const ApproveForm = () => {
  const country = useSelector(getCountry);

  return (
    <div className="d-flex flex-column gap-3 h-100">
      <div className="px-4 pt-4">
        <ReviewFormCheck />
      </div>
      <div className="px-4">
        <Line />
      </div>
      {country === 'CO' && <ApproveFormCO />}
      {country === 'MX' && <ApproveFormMX />}
    </div>
  );
};
