// libraries.
import { useSelector } from 'react-redux';

// selector.
import { CountryCode } from '@domains/country';
import { getSelectedTask } from '@domains/documents-review/store/slices/document-review-slice';

// slices.
import { useGetTaskDetailQuery } from '@domains/documents-review/store/api-slices/task-detail.endpoints';
import { useGetLeadDetailInfoQuery } from '@domains/lead-detail';

// utils.
import { formatPrice } from '@shared/utils';

const CUSTOM_FIELDS_FOR_SELLERS_MX = 'ask_price_mx';

export const DataSellersMX = () => {
  const detailTask = useSelector(getSelectedTask);

  const { data, isFetching } = useGetTaskDetailQuery({
    country: CountryCode.MX,
    nid: detailTask?.nid,
    customFields: CUSTOM_FIELDS_FOR_SELLERS_MX,
  });

  const { data: leadDetail, isFetching: fetching } = useGetLeadDetailInfoQuery({
    country: CountryCode.MX,
    nid: detailTask?.nid,
  });

  const NO_VALUE = 'No tiene';
  const address = data?.direccion || NO_VALUE;
  const askPrice = formatPrice(data?.ask_price_mx);
  const pricingInicial = formatPrice(leadDetail?.initial_pricing[0].price);
  const dataCorrecta = data?.data_correcta || NO_VALUE;

  if (isFetching || fetching) return <div>Cargando información...</div>;

  return (
    <>
      <span>|</span>
      <div>Dirección: {address}</div>
      <span>|</span>
      <div>Ask price: {askPrice}</div>
      <span>|</span>
      <div>Precio pricing inicial: {pricingInicial}</div>
      <span>|</span>
      <div>Data correcta: {dataCorrecta}</div>
    </>
  );
};
