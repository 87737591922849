// libraries.
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

// selectors.
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';
import { useUpdateTaskStatusMutation } from '@domains/documents-review/store/api-slices/update-task-status.endpoints';
import {
  ReviewOption,
  getSelectedTask,
  getShowModal,
  setShowModal,
} from '@domains/documents-review/store/slices/document-review-slice';

// components.
import { FieldsCO } from './FieldsCO/FieldsCO';
import { ModalForm } from '@shared/ModalForm/ModalForm';
import { FormActions } from '@pages/DocumentAuditPage/modules/DocumentTaskDetail/shared/FormActions/FormActions';
import { AbortTaskModal } from '@pages/DocumentAuditPage/modules/DocumentTaskDetail/shared/FormActions/AbortTaskModal';
import { defaultValuesCO, useHandleMutationToast } from '../utils';

export const ApproveFormCO = () => {
  const dispatch = useDispatch();
  const country = useSelector(getCountry) || '';
  const showModal = useSelector(getShowModal);
  const taskData = useSelector(getSelectedTask);

  const [mutateApproveCO, { isLoading, isSuccess, isError }] =
    useUpdateTaskStatusMutation();

  const handleOpenApproveModal = () => {
    dispatch(setShowModal(ReviewOption.APPROVE));
  };

  const methods = useForm({
    defaultValues: defaultValuesCO,
    mode: 'all',
  });

  const { isValid } = methods.formState;

  const onSubmit = (data: any) => {
    if (!taskData) return;

    mutateApproveCO({
      agent: taskData.agent,
      nid: taskData.nid,
      id: taskData.id,
      country: country,
      status: 'approved',
      task_result: {
        comment: data.comment,
        has_admin: Boolean(data.hasAdmin),
        admin_price: Number(data.adminPrice),
        construction_date: data.constructionDate,
        area_type: data.areaType,
        area: Number(data.area),
        baths: Number(data.baths),
        floor: Number(data.floor),
        has_garage: Boolean(data.hasGarage),
        garage: Number(data.garage),
        has_terrace: Boolean(data.hasTerrace),
        free_area: Number(data.freeArea),
        liens: data.liens,
        lender_bank: data.lenderBank,
      },
    });
  };

  useHandleMutationToast(isError, isSuccess);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div
          className="d-flex flex-column gap-2 pt-1 pb-4 px-4 overflow-scroll"
          style={{
            height: 'calc(100vh - 333px)',
          }}
        >
          <FieldsCO />
        </div>
        <FormActions
          buttonLabel="Aprobar"
          className="px-4 pt-3"
          onClick={handleOpenApproveModal}
          buttonDisabled={!isValid}
        />
        <ModalForm
          disabled={isLoading}
          onSave={methods.handleSubmit(onSubmit)}
          option={ReviewOption.APPROVE}
          show={showModal === ReviewOption.APPROVE}
          register={methods.register}
        />
      </form>
      <AbortTaskModal />
    </FormProvider>
  );
};
