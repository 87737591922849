// libraries.
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

// selector.
import { getEmail } from '@domains/user/store/slices/user-slice/user-slice';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const email = useSelector(getEmail);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes('/document-audit/task/')) {
      window.history.pushState(null, '', window.location.href);
      const handlePopState = () => {
        navigate(window.location.pathname);
      };
      window.addEventListener('popstate', handlePopState);
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [location, navigate]);

  if (!email) {
    return <Navigate to="/" replace />;
  }

  return children;
};
