// domains.
import { CountryCode } from '@domains/country';

// components.
import { DataSellersCO } from './DataSellersCO';
import { DataSellersMX } from './DataSellersMX';

// hooks.
import { useCountryComponent } from '@domains/user';

const DataSellersByCountryComponents = {
  [CountryCode.CO]: DataSellersCO,
  [CountryCode.MX]: DataSellersMX,
};

export const DataSellersByCountry = () => {
  const CountryComponent = useCountryComponent(DataSellersByCountryComponents);

  return CountryComponent ? (
    <CountryComponent />
  ) : (
    <div>No hay país definido</div>
  );
};
