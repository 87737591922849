// libraries.
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// slice.
import {
  ReviewOption,
  getSelectedTask,
  getShowModal,
  setDefaultState,
  setShowModal,
} from '@domains/documents-review/store/slices/document-review-slice';
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';
import { useUpdateTaskStatusMutation } from '@domains/documents-review/store/api-slices/update-task-status.endpoints';

// components.
import { ModalForm } from '@shared/ModalForm/ModalForm';

interface AbortTaskModalProps {
  taskId?: string;
}

export const AbortTaskModal = ({ taskId }: AbortTaskModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const country = useSelector(getCountry);
  const showModal = useSelector(getShowModal);
  const taskData = useSelector(getSelectedTask);

  const id = taskId || taskData?.id;

  const [mutateAbort, { isLoading, isSuccess, isError }] =
    useUpdateTaskStatusMutation();

  const handleAbandon = () => {
    if (!id) return;

    mutateAbort({
      id: id,
      agent: '',
      country: country,
      status: 'aborted',
    });
  };

  useEffect(() => {
    if (isError) {
      toast.error('Error al abandonar esta tarea', {
        toastId: 'errorAbortTask',
      });
    } else if (isSuccess) {
      toast.success('Tarea abortada correctamente', {
        toastId: 'successRejectTask',
      });
      dispatch(setShowModal(false));
      dispatch(setDefaultState());
      navigate('/document-audit/tasks');
    }
  }, [isError, isSuccess]);

  return (
    <ModalForm
      disabled={isLoading}
      show={showModal === ReviewOption.ABORT}
      option={ReviewOption.ABORT}
      subtitle="Si abandonas, perderás los cambios no guardados."
      submitLabel="Abandonar"
      onSave={handleAbandon}
    />
  );
};
