// libraries.
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// selectors.
import {
  getCountry,
  getEmail,
  getRole,
  setRoles,
} from '@domains/user/store/slices/user-slice/user-slice';

// endpoints.
import {
  DocRolesEnum,
  useGetDocReviewRolesQuery,
} from '@domains/user/store/api-slices/roles-documents.endpoints';
import {
  RolesPolynatorEnum,
  useGetRolesPolynatorQuery,
} from '@domains/user/store/api-slices/roles-polynator.endpoint';

export const useRoleForTools = () => {
  const dispatch = useDispatch();

  const email = useSelector(getEmail);
  const country = useSelector(getCountry)!;
  const rolePolynator = useSelector(getRole) || [];

  const isPolynatorRole =
    rolePolynator.includes(RolesPolynatorEnum.PolygonCreator) ||
    rolePolynator.includes(RolesPolynatorEnum.PolygonViewer);

  const isDocReviewRole = rolePolynator.includes(DocRolesEnum.DocumentReviewer);

  const fetchPolynator = country && email && !isPolynatorRole;
  const fetchDocuments = country && email && !isDocReviewRole;

  const {
    data: docRoleData,
    isLoading: isLoadingDoc,
    isSuccess: isSuccessDoc,
    isError: isErrorDoc,
  } = useGetDocReviewRolesQuery({ country, email }, { skip: !fetchDocuments });

  const {
    data: polynatorRoleData,
    isLoading: isLoadingPolynator,
    isSuccess: isSuccessPolynator,
    isError: isErrorPolynator,
  } = useGetRolesPolynatorQuery({ country, email }, { skip: !fetchPolynator });

  const docRole = docRoleData?.role;
  const polynatorRole = polynatorRoleData?.role;

  useEffect(() => {
    if (docRole) {
      dispatch(setRoles(docRole));
    } else if (polynatorRole) {
      dispatch(setRoles(polynatorRole));
    }
  }, [docRole, polynatorRole, dispatch]);

  useEffect(() => {
    if (isLoadingDoc || isLoadingPolynator) {
      toast.loading('Validando Rol..', { toastId: 'RoleForTool' });
    } else {
      toast.dismiss('RoleForTool');
    }
  }, [isLoadingDoc, isLoadingPolynator]);

  useEffect(() => {
    if (isSuccessDoc && docRole === DocRolesEnum.DocumentReviewer) {
      toast.success('Rol revisor de documentos');
    }
    if (isSuccessPolynator) {
      const isCreator = polynatorRole === RolesPolynatorEnum.PolygonCreator;
      const roleMessage = isCreator
        ? 'creador de polígonos'
        : 'validador de polígonos';
      toast.success(`Rol ${roleMessage}`);
    }
  }, [isSuccessDoc, isSuccessPolynator, docRole, polynatorRole]);

  useEffect(() => {
    if (isErrorDoc || isErrorPolynator) {
      toast.error('Error al obtener el rol, contacte a sistemas :V');
    }
  }, [isErrorDoc, isErrorPolynator]);
};
