// libraries.
import { Layout } from '@shared';
import { Container } from 'react-bootstrap';

// hooks.
import { useRoleForTools } from '@domains/user';

// shared
import { SidebarHome } from './components/SidebarHome/SidebarHome';
import { EmbeddedBoard } from '@shared/ReleaseNotes/ReleaseNotes';

export const HomePage = () => {
  const board =
    'https://docs.google.com/presentation/d/1Tm2JLfsE4LCQDq7Huom-K1N0RWZJUVanHFyk7hbvbgs/embed?start=false&loop=false&delayms=3000';

  useRoleForTools();

  return (
    <Layout>
      <SidebarHome />
      <Container fluid className="h-100 w-100 p-3">
        <EmbeddedBoard url={board} className="h-100 w-100 rounded" />
      </Container>
    </Layout>
  );
};
