// libraries.
import { ModelError } from '@store/apiSlices/interfaces/interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

// slices.
import { apiDocumentsPortal } from '@store/apiSlices/apiSlices';

// interfaces.
import {
  ListTasksParams,
  ListTasksResponse,
} from '@domains/documents-review/interfaces/interfaces';

export const listTasks = apiDocumentsPortal.injectEndpoints({
  endpoints: builder => ({
    getListTasks: builder.query({
      query: ({ country, status }: ListTasksParams) => {
        return `/list_tasks?country=${country}&status=${status}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: 'getListTasks',
        };
        return modelError;
      },
      transformResponse: (response: ListTasksResponse) => {
        return response.results.body;
      },
      providesTags: ['Tasks'],
    }),
  }),
});

export const { useGetListTasksQuery } = listTasks;
