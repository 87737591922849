// libraries.
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// slices.
import {
  setDefaultState,
  setShowModal,
} from '@domains/documents-review/store/slices/document-review-slice';

const LIEN_LIST_DATA = [
  ['Libre de Gravámenes ó Afectación familiar', 'lien_free_family_affectation'],
  ['Patrimonio hijos', 'children_assets'],
  ['Patrimonio', 'assets'],
  ['Hipoteca ó Hipoteca + Afectación Familiar', 'mortgage_family'],
  ['Hipoteca + Patrimonio con hijos', 'mortgage_children_assets'],
  ['Hipoteca + Patrimonio', 'mortgage_assets'],
  ['Embargo', 'embargo'],
  ['Leasing', 'leasing'],
  ['Liquidación de Herencia', 'inheritance_settlement'],
  ['En trámite', 'in_process'],
  ['Afectación familia', 'family_affectation'],
  ['Hipoteca', 'mortgage'],
  ['Prohibición transferencia del dominio', 'transfer_prohibition'],
  ['Derecho de preferencia', 'preference_right'],
  ['Pacto de retroventa', 'repurchase_agreement'],
  ['Valorización', 'valuation'],
  ['Sucesión', 'inheritance'],
  ['Otro', 'others'],
  ['Ninguno', 'none'],
  ['Crédito Hipotecario', 'mortgage_credit'],
  ['Patrimonio familiar', 'family_patrimony'],
  ['No lo sé', 'unanswered'],
  ['Subsidio', 'subsidy'],
  ['Subsidio Infonavit', 'subsidy_infonavit'],
  ['Subsidio Fovissste', 'subsidy_fovissste'],
  ['Crédito INVI beneficio de vivienda CDMX', 'invi_cdmx_credit'],
  ['Crédito hipotecario ISFAM', 'isfam_credit'],
  ['Adjudicaciones procedentes de gravámenes', 'adjudication_lien'],
  ['Adjudicaciones procedentes de remate', 'adjudication_auction'],
];

const LIEN_LIST = LIEN_LIST_DATA.map(([label, value]) => ({
  label,
  value,
}));

export const DROPDOWN_OPTIONS = {
  YES_NO: [
    { value: 1, label: 'Sí' },
    { value: 0, label: 'No' },
  ],
  AREA_TYPE: [
    { value: 'private', label: 'Privada' },
    { value: 'built', label: 'Construida' },
  ],
  PROPERTY_TYPE: [
    { value: '1', label: 'Departamento en edificio' },
    { value: '2', label: 'Casa sola' },
    { value: '3', label: 'Casa en condominio' },
  ],
  PROPERTY_DOCUMENT_TYPE_OPTIONS: [
    { value: 'deed', label: 'Escritura' },
    { value: 'appraisal', label: 'Avalúo' },
    { value: 'condominium_regime', label: 'Régimen en condominio' },
    { value: 'remodeling_measures', label: 'Medidas Remodelaciones' },
    { value: 'architectural_plan', label: 'Plano arquitectónico' },
    {
      value: 'property_tax_satellite_measurement',
      label: 'Boleta predial + medición satelital',
    },
    { value: 'lien_free_certificate', label: 'Certificado libre gravamen' },
    { value: 'construction_license', label: 'Licencia construcción' },
    {
      value: 'public_property_registry',
      label: 'Registro público de la propiedad',
    },
  ],
  BANK_LIST: [
    { label: 'Realizado por el cliente', value: 'by_client' },
    { label: 'Cavipetrol', value: 'cavipetrol' },
    { label: 'FNA', value: 'fna' },
    { label: 'Bancoomeva', value: 'bancoomeva' },
    { label: 'Itau', value: 'itau' },
    { label: 'La Hipotecaria', value: 'la_hipotecaria' },
    { label: 'Banco de Occidente', value: 'banco_occidente' },
    { label: 'Otras entidades', value: 'others' },
    { label: 'Persona natural', value: 'natural_person' },
    { label: 'Caja social', value: 'caja_social' },
    { label: 'Colsubsidio', value: 'colsubsidio' },
    { label: 'Credifamilia', value: 'credifamilia' },
    { label: 'Colpatria', value: 'colpatria' },
    { label: 'Cooperativa confiar', value: 'coop_confiar' },
    { label: 'Banco popular', value: 'banco_popular' },
    { label: 'AV Villas', value: 'avvillas' },
    { label: 'Davivienda', value: 'davivienda' },
    { label: 'Bancolombia', value: 'bancolombia' },
    { label: 'Banco de Bogotá', value: 'banco_bogota' },
    { label: 'Fondo de empleados grupo éxito', value: 'fondo_emp_exito' },
    { label: 'Compensar', value: 'compensar' },
    { label: 'BBVA', value: 'bbva' },
    { label: 'Comfama', value: 'comfama' },
    { label: 'Cooperativa Alianza', value: 'coop_alianza' },
  ],
  LIEN_LIST,
};

export const defaultValuesMX = {
  complex: '',
  adminPrice: '',
  propertyType: '',
  medianZone: '',
  zipCode: '',
  committeePriceArea: '',
  committeeLandArea: '',
  rooms: '',
  baths: '',
  floor: '',
  elevator: '',
  hasGarageDoc: null,
  garage: '',
  yearsOld: '',
};

export const defaultValuesCO = {
  hasAdmin: null,
  adminPrice: '',
  constructionDate: '',
  areaType: '',
  area: '',
  baths: '',
  floor: '',
  hasGarage: null,
  garage: '',
  hasTerrace: null,
  freeArea: '',
  liens: '',
  lenderBank: '',
};

export const useHandleMutationToast = (
  isError: boolean,
  isSuccess: boolean
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      toast.error('Error al aprobar esta tarea', {
        toastId: 'errorApproveTask',
      });
    } else if (isSuccess) {
      toast.success('Tarea aprobada correctamente', {
        toastId: 'successApproveTask',
      });
      dispatch(setShowModal(false));
      dispatch(setDefaultState());
      navigate('/document-audit/tasks');
    }
  }, [isError, isSuccess, dispatch]);
};
