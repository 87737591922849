import { NumericFormat } from 'react-number-format';
import { useFormContext } from 'react-hook-form';
import { WrapperInputField } from '@shared/InputField/styles';

interface PriceInputProps {
  label: string;
  name: string;
  required?: boolean;
  placeholder?: string;
}

export const PriceInputField = ({
  label,
  name,
  required = false,
  placeholder = 'Escribir',
}: PriceInputProps) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <WrapperInputField>
      <label htmlFor={name}>{label}</label>
      <NumericFormat
        id={name}
        thousandSeparator="."
        decimalSeparator=","
        prefix="$ "
        allowNegative={false}
        placeholder={placeholder}
        {...register(name, { required: required ? 'Campo requerido' : false })}
        onValueChange={values => setValue(name, values.value)}
      />
      {errors[name] && (
        <small className="text-danger my-1 fw-bold">
          {errors[name]?.message as string}
        </small>
      )}
    </WrapperInputField>
  );
};
