// libraries.
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// hooks.
import { useAbortTask } from '@pages/DocumentAuditPage/modules/DocumentTaskDetail/shared/AbortTask/hooks/useAbortTask';

// components.
import { AbortTaskModal } from '@pages/DocumentAuditPage/modules/DocumentTaskDetail/shared/FormActions/AbortTaskModal';

// selectors.
import { getEmail } from '@domains/user/store/slices/user-slice/user-slice';

// interface.
import { TaskItem } from '@domains/documents-review/interfaces/interfaces';

// assets.
import { IconProgress, IconRemove, IconWatch } from '@assets/icons';

interface TaskActionsByAgentProps {
  task: TaskItem;
  onViewTask: () => void;
}

export const TaskActionsByAgent = ({
  task,
  onViewTask,
}: TaskActionsByAgentProps) => {
  const email = useSelector(getEmail);
  const { handleOpenAbandonModal } = useAbortTask();

  const isDifferentAgent = task.agent && task.agent !== email;

  if (isDifferentAgent) {
    return (
      <div className="d-flex justify-content-center gap-1">
        <Button
          variant="link"
          className="d-flex flex-1 py-1 px-0 align-items-center gap-1 justify-content-center text-primary rows"
          onClick={onViewTask}
        >
          <IconWatch /> Ver
        </Button>
        <Button
          variant="link"
          className="d-flex flex-1 py-1 px-0 align-items-center justify-content-center text-danger rows"
          onClick={handleOpenAbandonModal}
        >
          <IconRemove /> Quitar
        </Button>
        <AbortTaskModal taskId={task.id} />
      </div>
    );
  }

  return (
    <Button
      variant="link"
      className="text-muted w-100 d-flex align-items-center justify-content-center p-1 rows"
      onClick={onViewTask}
    >
      <IconProgress /> En proceso
    </Button>
  );
};
