// interface.
import { IRoute } from '@routes/routes';

// types.
import { documentAuditPath, documentTask } from './routes.types';

// components.
import { DocumentAuditsDashboard } from '../modules/DocumentsAuditDashboard/DocumentsAuditDashboard';
import { DocumentTaskDetail } from '../modules/DocumentTaskDetail/DocumentTaskDetail';

export const routesDocumentAuditPage: IRoute[] = [
  {
    id: 'document-audit-dashboard',
    path: `${documentAuditPath}`,
    element: <DocumentAuditsDashboard />,
  },
  {
    id: 'document-task-detail',
    path: `${documentTask}`,
    element: <DocumentTaskDetail />,
  },
];
