// domains.
import { CountryCode } from '@domains/country';

// hooks.
import {
  CountryDictionary,
  useCountryDictionary,
} from '@domains/user/hooks/useCountryComponent/useCountryDictionary';

// dictionaries.
import { rejectReasonsCO } from './DocumentReview/DocumentForms/RejectForm/utils/rejectReasonsCO';
import { rejectReasonsMX } from './DocumentReview/DocumentForms/RejectForm/utils/rejectReasonsMX';

// interfaces.
import { RejectReason } from '@domains/documents-review/interfaces/interfaces';

export const rejectReasonsByCountry: CountryDictionary<
  { id: string; label: string }[]
> = {
  [CountryCode.CO]: rejectReasonsCO,
  [CountryCode.MX]: rejectReasonsMX,
};

export const getRejectReasonLabel = (rejectReason: RejectReason | null) => {
  const rejectionReasons = useCountryDictionary(rejectReasonsByCountry) || [];

  if (!rejectReason) return '';
  const selectedReason = rejectionReasons.find(
    reason => reason.id === rejectReason.id
  );
  return selectedReason ? selectedReason.label : '';
};
