// libraries.
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

// components.
import { Line } from '@shared/styles';
import { ModalForm } from '@shared/ModalForm/ModalForm';
import { FormActions } from '@pages/DocumentAuditPage/modules/DocumentTaskDetail/shared/FormActions/FormActions';
import { RejectDropdown } from './RejectDropdown';
import { AbortTaskModal } from '@pages/DocumentAuditPage/modules/DocumentTaskDetail/shared/FormActions/AbortTaskModal';
import { ReviewFormCheck } from '../ReviewFormCheck';

// selectors.
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';
import {
  ReviewOption,
  getSelectedTask,
  getShowModal,
  setDefaultState,
  setShowModal,
} from '@domains/documents-review/store/slices/document-review-slice';
import { useCountryDictionary } from '@domains/user/hooks/useCountryComponent/useCountryDictionary';
import { useUpdateTaskStatusMutation } from '@domains/documents-review/store/api-slices/update-task-status.endpoints';

// utils.
import { WrapperRejectForm } from '../../../styles';
import { getRejectReasonLabel, rejectReasonsByCountry } from '../../../utils';

export const RejectForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const country = useSelector(getCountry) || 'MX';
  const showModal = useSelector(getShowModal);
  const taskData = useSelector(getSelectedTask);

  const methods = useForm({
    defaultValues: {
      comment: '',
      rejectReason: null,
    },
    mode: 'all',
  });

  const rejectReason = methods.watch('rejectReason');
  const label = getRejectReasonLabel(rejectReason);
  const rejectionReasons = useCountryDictionary(rejectReasonsByCountry) || [];

  const [mutateReject, { isLoading, isSuccess, isError }] =
    useUpdateTaskStatusMutation();

  const handleOpenRejectModal = () => {
    dispatch(setShowModal(ReviewOption.REJECT));
  };

  const onSubmit = (data: any) => {
    if (!taskData) return;

    mutateReject({
      agent: taskData.agent,
      nid: taskData.nid,
      id: taskData.id,
      country: country,
      status: 'rejected',
      task_result: {
        reject_reason: data.rejectReason,
        comment: data.comment,
      },
    });
  };

  useEffect(() => {
    if (isError) {
      toast.error('Error al rechazar esta tarea', {
        toastId: 'errorRejectTask',
      });
    } else if (isSuccess) {
      toast.success('Tarea rechazada correctamente', {
        toastId: 'successRejectTask',
      });
      dispatch(setShowModal(false));
      dispatch(setDefaultState());
      navigate('/document-audit/tasks');
    }
  }, [isError, isSuccess]);

  return (
    <>
      <FormProvider {...methods}>
        <form className="h-100" onSubmit={methods.handleSubmit(onSubmit)}>
          <WrapperRejectForm className="d-flex flex-column gap-3 p-4">
            <div className="d-flex flex-column gap-3">
              <ReviewFormCheck />
              <Line />
              <RejectDropdown reasons={rejectionReasons} />
            </div>
            <FormActions
              buttonLabel="Rechazar"
              buttonDisabled={!rejectReason}
              onClick={handleOpenRejectModal}
            />
          </WrapperRejectForm>
          <ModalForm
            disabled={isLoading}
            onSave={methods.handleSubmit(onSubmit)}
            option={ReviewOption.REJECT}
            subtitle={label}
            show={showModal === ReviewOption.REJECT}
            register={methods.register}
          />
        </form>
        <AbortTaskModal />
      </FormProvider>
    </>
  );
};
