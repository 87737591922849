// libraries.
import { Form } from 'react-bootstrap';
import { robotoRegular } from '@habitech/shared';
import { useFormContext } from 'react-hook-form';

interface RejectDropdownProps {
  reasons: { id: string; label: string }[];
}

export const RejectDropdown = ({ reasons }: RejectDropdownProps) => {
  const { setValue } = useFormContext();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;

    if (!selectedId) {
      setValue('rejectReason', null, { shouldValidate: true });
      return;
    }

    const selectedReason = reasons.find(reason => reason.id === selectedId);

    if (selectedReason) {
      const finalReason = {
        ...selectedReason,
        category: 'revision_documentos',
        value: selectedReason.label,
      };
      setValue('rejectReason', finalReason, { shouldValidate: true });
    }
  };

  return (
    <Form.Group
      style={{
        fontSize: '14px',
        fontFamily: robotoRegular,
      }}
    >
      <Form.Label htmlFor="rejectReason">Razón del rechazo*</Form.Label>
      <Form.Select id="rejectReason" onChange={handleChange}>
        <option className="option" value="">
          Selecciona una opción
        </option>
        {reasons.map(({ id, label }) => (
          <option key={id} value={id}>
            {label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};
