import {
  electricPurple800,
  monserratRegular,
  robotoRegular,
  scarpaGray50,
  scarpaGray500,
} from '@habitech/shared';
import { styled } from 'styled-components';

export const WrapperModal = styled.div`
  padding: 24px;
  background: ${scarpaGray50};
  color: ${scarpaGray500};
  border-radius: 8px;
  .subtitle {
    font-family: ${robotoRegular};
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 8px;
  }
  .modal-title {
    font-size: 18px;
    font-weight: bold;
    color: ${electricPurple800};
    font-family: ${monserratRegular};
  }
  .form-label,
  .form-control {
    font-size: 14px;
    font-family: ${robotoRegular};
    padding-bottom: 5px;
  }
  .form-control {
    height: 100px;
  }
  textarea {
    overflow: auto;
    scrollbar-width: none;
  }
  textarea::-webkit-scrollbar {
    display: none;
  }
`;
