import { monserratSemiBold, robotoRegular } from '@habitech/shared';
import { styled } from 'styled-components';

export const WrapperReviewForm = styled.div`
  .form-check-input:checked {
    background-color: rgb(124, 1, 255);
    border-color: rgb(124, 1, 255);
  }
  .form-check-input:focus {
    box-shadow: 0 0 0 0.2rem #f1e5ff;
  }
  font-size: 14px;
  font-family: ${robotoRegular};
`;

export const WrapperRejectForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .form-select {
    font-size: 14px;
  }
`;

export const WrapperTaskDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  font-family: ${robotoRegular};
  .icon-detail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: rgba(248, 242, 255, 1);
    border-radius: 8px;
  }
  .bread-crumbs {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  .arrow {
    cursor: pointer;
  }
  .my-task {
    font-weight: 400;
    font-size: 12px;
  }
  .review {
    font-weight: 600;
    font-size: 12px;
  }
  .nid {
    font-family: ${monserratSemiBold};
    color: rgba(37, 33, 41, 1);
    font-size: 18px;
    font-weight: 900;
  }
  .properties {
    display: flex;
    gap: 8px;
    font-size: 14px;
  }
  .badge-inmobiliaria {
    font-size: 13px;
    background-color: rgba(204, 229, 221, 1) !important;
    color: #206a5d !important;
  }
  .badge-comite {
    font-size: 13px;
    background-color: rgba(230, 239, 253, 1) !important;
    color: #1e3a8a !important;
  }
`;
