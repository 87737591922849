// assets.
import HabiPricingIcon from './habi-pricing.svg';
import DeleteIcon from './delete-icon.svg';
import EditIcon from './edit-icon.svg';
import HabiIcon from './habi-icon.svg';
import ToolPolygonIcon from './tool-polygon-icon.svg';
import MedianZonesIcon from './median-zones.svg';
import IntersectionsIcon from './intersections.svg';
import LogOutIcon from './log-out.svg';
import SearchIcon from './search-icon.svg';
import ProgressIcon from './progress-icon.svg';
import DocumentIcon from './document-icon.svg';
import DocumentToolIcon from './document-button.svg';
import PictureIcon from './picture-icon.svg';
import ApproveIcon from './approve.svg';
import RejectIcon from './reject.svg';
import WarningIcon from './warning.svg';
import ArrowIcon from './left-arrow.svg';
import WatchIcon from './watch-icon.svg';
import RemoveIcon from './remove-icon.svg';
interface IconProps {
  fill?: string;
  height?: string;
  width?: string;
}

export const IconHabiPricing = ({
  width = '185',
  height = '80',
  fill,
}: IconProps) => {
  return (
    <HabiPricingIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconDelete = ({
  width = '20',
  height = '20',
  fill,
}: IconProps) => {
  return <DeleteIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconEdit = ({ width = '16', height = '16', fill }: IconProps) => {
  return <EditIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconHabi = ({ width = '24', height = '24', fill }: IconProps) => {
  return <HabiIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconToolPolygon = ({
  width = '24',
  height = '24',
  fill,
}: IconProps) => {
  return (
    <ToolPolygonIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconMedianZones = ({
  width = '24',
  height = '24',
  fill,
}: IconProps) => {
  return (
    <MedianZonesIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconIntersections = ({
  width = '20',
  height = '20',
  fill,
}: IconProps) => {
  return (
    <IntersectionsIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconLogOut = ({
  width = '24',
  height = '24',
  fill,
}: IconProps) => {
  return <LogOutIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconSearch = ({
  width = '20',
  height = '20',
  fill,
}: IconProps) => {
  return <SearchIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconProgress = ({
  width = '20',
  height = '20',
  fill,
}: IconProps) => {
  return <ProgressIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconDocument = ({
  width = '20',
  height = '20',
  fill,
}: IconProps) => {
  return <DocumentIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconDocumentTool = ({
  width = '24',
  height = '24',
  fill,
}: IconProps) => {
  return (
    <DocumentToolIcon style={{ color: fill }} width={width} height={height} />
  );
};
export const IconApprove = ({
  width = '24',
  height = '24',
  fill,
}: IconProps) => {
  return <ApproveIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconReject = ({
  width = '24',
  height = '24',
  fill,
}: IconProps) => {
  return <RejectIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconWarning = ({
  width = '24',
  height = '24',
  fill,
}: IconProps) => {
  return <WarningIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconWatch = ({ width = '20', height = '20', fill }: IconProps) => {
  return <WatchIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconRemove = ({
  width = '20',
  height = '20',
  fill,
}: IconProps) => {
  return <RemoveIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconArrow = ({ width = '24', height = '24', fill }: IconProps) => {
  return <ArrowIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconPicture = ({
  width = '20',
  height = '18',
  fill,
}: IconProps) => {
  return <PictureIcon style={{ color: fill }} width={width} height={height} />;
};
