// libraries.
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

// components.
import { InputField } from '@shared/InputField/InputField';
import { NumericField } from '@shared/NumericField/NumericField';
import { DropdownField } from '@shared/DropdownField/DropdownField';
import { PriceInputField } from '@shared/PriceInputField/PriceInputField';

// utils.
import { DROPDOWN_OPTIONS } from '../../utils';
import { MultiSelectDropdownField } from '@shared/MultiSelectDropdownField/MultiSelectDropdownField';

export const FieldsCO = () => {
  const { watch, unregister } = useFormContext();

  const hasAdmin = watch('hasAdmin');
  const hasGarage = watch('hasGarage');
  const hasTerrace = watch('hasTerrace');

  useEffect(() => {
    if (hasGarage !== 1) unregister('garage');
    if (hasAdmin !== 1) unregister('adminPrice');
  }, [hasGarage, hasAdmin, unregister]);

  return (
    <>
      <div className="d-flex gap-2">
        <DropdownField
          label="Recibo admin.*"
          name="hasAdmin"
          options={DROPDOWN_OPTIONS.YES_NO}
          required
        />
        {hasAdmin === 1 && (
          <PriceInputField label="Valor admin." name="adminPrice" required />
        )}
      </div>
      <InputField
        label="Fecha construcción del inmueble*"
        type="date"
        name="constructionDate"
        required
      />
      <div className="d-flex gap-2">
        <DropdownField
          label="Tipo de área"
          name="areaType"
          options={DROPDOWN_OPTIONS.AREA_TYPE}
        />
        <InputField
          label="Área*"
          maxLength={3}
          name="area"
          pattern={/^\d{1,3}$/}
          placeholder="Área"
          subLabel="m²"
          required
        />
      </div>
      <div className="d-flex gap-2">
        <NumericField
          label="Baños*"
          name="baths"
          placeholder="Baños"
          required
        />
        <NumericField label="Piso*" name="floor" placeholder="Piso" required />
      </div>
      <div className="d-flex gap-2">
        <DropdownField
          label="Garaje*"
          name="hasGarage"
          options={DROPDOWN_OPTIONS.YES_NO}
          required
        />
        {hasGarage === 1 && (
          <NumericField
            label="Cantidad de garajes*"
            name="garage"
            placeholder="Garage"
            required
          />
        )}
      </div>
      <div className="d-flex gap-2">
        <DropdownField
          label="Balcón/Terraza (opc)"
          name="hasTerrace"
          options={DROPDOWN_OPTIONS.YES_NO}
        />
        {hasTerrace === 1 && (
          <InputField
            label="Área libre (opc)"
            maxLength={3}
            name="freeArea"
            pattern={/^\d{1,3}$/}
            placeholder="Área"
            subLabel="m²"
          />
        )}
      </div>
      <MultiSelectDropdownField
        label="Gravámenes (opcional)"
        name="liens"
        options={DROPDOWN_OPTIONS.LIEN_LIST}
      />
      <DropdownField
        label="Banco hipoteca (opcional)"
        name="lenderBank"
        options={DROPDOWN_OPTIONS.BANK_LIST}
      />
    </>
  );
};
