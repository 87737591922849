// libraries.
import { useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useController, useFormContext } from 'react-hook-form';

// styles.
import { WrapperInputField } from '@shared/InputField/styles';

interface MultiSelectDropdownFieldProps {
  label: string;
  name: string;
  options: { value: string; label: string }[];
  required?: boolean;
}

export const MultiSelectDropdownField = ({
  label,
  name,
  options,
  required = false,
}: MultiSelectDropdownFieldProps) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: required ? { required: 'Campo requerido' } : {},
    defaultValue: [],
  });

  const [selected, setSelected] = useState<string[]>(value || []);

  const handleSelect = (optionValue: string) => {
    const newSelected = selected.includes(optionValue)
      ? selected.filter(item => item !== optionValue)
      : [...selected, optionValue];

    setSelected(newSelected);
    onChange(newSelected.join(';'));
  };

  return (
    <WrapperInputField>
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
      <Dropdown>
        <Dropdown.Toggle className="w-100 multi-dropdown">
          {selected.length > 0
            ? `${selected.length} opción(es) seleccionada(s)`
            : 'Seleccionar opciones'}
        </Dropdown.Toggle>
        <Dropdown.Menu className="wrapper-items" flip={true}>
          {options.map(option => (
            <Dropdown.Item
              key={option.value}
              as="div"
              onClick={e => e.stopPropagation()}
            >
              <Form.Check
                type="checkbox"
                id={`checkbox-${option.value}`}
                label={option.label}
                checked={selected.includes(option.value)}
                onChange={e => {
                  handleSelect(option.value);
                }}
              />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {error && (
        <small className="text-danger my-1 fw-bold">{error.message}</small>
      )}
    </WrapperInputField>
  );
};
