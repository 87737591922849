// libraries.
import { Outlet } from 'react-router-dom';

// components.

export const DocumentAuditsPage = () => {
  return (
    <>
      <Outlet />
    </>
  );
};
