// libraries.
import { Button } from 'react-bootstrap';

// shared.
import { Line } from '@shared/styles';

interface FormActionsProps {
  buttonLabel: string;
  buttonDisabled?: boolean;
  className?: string;
  onClick?: () => void;
}

export const FormActions = ({
  buttonLabel,
  buttonDisabled,
  className = '',
  onClick,
}: FormActionsProps) => {
  return (
    <div className={`d-flex flex-column gap-3 ${className}`}>
      <Line />
      <div className="d-flex">
        <Button
          className="w-100 btn-habi"
          type="button"
          onClick={onClick}
          disabled={buttonDisabled}
        >
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};
