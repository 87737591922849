// libraries.
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// selectors.
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';

// assets.
import { IconDocumentTool, IconPicture } from '@assets/icons';

export const ReviewButtons = () => {
  const { nid } = useParams<{ nid: string }>();
  const country = useSelector(getCountry) || 'CO';

  const LINKS = {
    MX: {
      documents: `https://app.hubspot.com/contacts/6215805/record/0-3/${nid}`,
      media:
        'https://drive.google.com/drive/folders/1BgkFvzrmlrmoYhr4SyUIvUh9ZMCJLRiD',
    },
    CO: {
      documents: `https://app.hubspot.com/contacts/6215805/record/0-3/${nid}`,
      media: 'https://drive.google.com/drive/folders/0ALK6cbkhSUUSUk9PVA',
    },
  };

  const handleOpenLink = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <div className="d-flex gap-2 p-4">
      <Button
        className="d-flex align-items-center gap-2 btn-habi-white"
        onClick={() => handleOpenLink(LINKS[country].documents)}
        variant="light"
      >
        <IconDocumentTool fill="black" height="18" width="20" />
        <span>Ver documentos</span>
      </Button>

      <Button
        className="d-flex align-items-center gap-2 btn-habi-white"
        onClick={() => handleOpenLink(LINKS[country].media)}
        variant="light"
      >
        <IconPicture />
        <span>Ver fotos y videos</span>
      </Button>
    </div>
  );
};
