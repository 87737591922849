import { useDispatch } from 'react-redux';
import {
  setShowModal,
  ReviewOption,
} from '@domains/documents-review/store/slices/document-review-slice';

export const useAbortTask = () => {
  const dispatch = useDispatch();

  const handleOpenAbandonModal = () => {
    dispatch(setShowModal(ReviewOption.ABORT));
  };

  return { handleOpenAbandonModal };
};
