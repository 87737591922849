// libraries.
import { Col, Row } from 'react-bootstrap';

// components.
import { Layout } from '@shared';
import { DocumentsTable } from './components/DocumentsTable/DocumentsTable';
import { SidebarDocuments } from './components/SideBarDocuments/SideBarDocuments';
import { DocumentsActionsBar } from './components/DocumentsActionsBar/DocumentsActionsBar';

export const DocumentAuditsDashboard = () => {
  return (
    <Layout>
      <SidebarDocuments />
      <div className="h-100 w-100">
        <DocumentsActionsBar />
        <Row
          className="m-0"
          style={{ height: 'calc(100% - 96px)', width: '100%' }}
        >
          <Col className="p-4 h-100">
            <DocumentsTable />
          </Col>
        </Row>
      </div>
    </Layout>
  );
};
