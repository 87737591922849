// libraries.
import { ModelError } from '@store/apiSlices/interfaces/interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

// slices.
import { apiSellersPortal } from '@store/apiSlices/apiSlices';

// interfaces.
import {
  ResponseDataSellers,
  TaskDetailParams,
} from '@domains/documents-review/interfaces/interfaces';

export const taskDetail = apiSellersPortal.injectEndpoints({
  endpoints: builder => ({
    getTaskDetail: builder.query({
      query: ({ nid, customFields }: TaskDetailParams) => {
        return `/api/v1/deal?nid=${nid}&custom_fields=${customFields}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: 'getTaskDetail',
        };
        return modelError;
      },
      transformResponse: (response: ResponseDataSellers[]) => {
        const data = response[0];
        return data;
      },
    }),
  }),
});

export const { useGetTaskDetailQuery } = taskDetail;
