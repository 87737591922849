// libraries.
import { useFormContext } from 'react-hook-form';
import { Form, InputGroup } from 'react-bootstrap';

// styles.
import { WrapperInputField } from './styles';

interface InputFieldProps {
  label: string;
  maxLength?: number;
  name: string;
  pattern?: RegExp;
  placeholder?: string;
  required?: boolean;
  subLabel?: string;
  type?: string;
}

export const InputField = ({
  label,
  maxLength,
  name,
  pattern,
  placeholder = '',
  type = 'text',
  required = false,
  subLabel,
}: InputFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const validationRules = {
    ...(required && { required: 'Campo requerido' }),
    ...(pattern && { pattern }),
  };

  return (
    <WrapperInputField>
      <Form.Group>
        <Form.Label htmlFor={name}>{label}</Form.Label>
        <InputGroup>
          <Form.Control
            id={name}
            maxLength={maxLength}
            type={type}
            placeholder={placeholder}
            {...register(name, validationRules)}
          />
          {subLabel && <InputGroup.Text>{subLabel}</InputGroup.Text>}
        </InputGroup>
      </Form.Group>
      <div className="d-flex justify-content-center">
        {errors[name] && (
          <small className="text-danger my-1 fw-bold">
            {errors[name]?.message as string}
          </small>
        )}
      </div>
    </WrapperInputField>
  );
};
