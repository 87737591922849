// libraries.
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

// selectors.
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';
import {
  ReviewOption,
  getSelectedTask,
  getShowModal,
  setShowModal,
} from '@domains/documents-review/store/slices/document-review-slice';
import { useUpdateTaskStatusMutation } from '@domains/documents-review/store/api-slices/update-task-status.endpoints';

// components.
import { FieldsMX } from './FieldsMX/FieldsMX';
import { ModalForm } from '@shared/ModalForm/ModalForm';
import { FormActions } from '@pages/DocumentAuditPage/modules/DocumentTaskDetail/shared/FormActions/FormActions';
import { AbortTaskModal } from '@pages/DocumentAuditPage/modules/DocumentTaskDetail/shared/FormActions/AbortTaskModal';
import { defaultValuesMX, useHandleMutationToast } from '../utils';

export const ApproveFormMX = () => {
  const dispatch = useDispatch();
  const country = useSelector(getCountry) || '';
  const showModal = useSelector(getShowModal);
  const taskData = useSelector(getSelectedTask);

  const [mutateApproveMX, { isLoading, isSuccess, isError }] =
    useUpdateTaskStatusMutation();

  const methods = useForm({
    defaultValues: defaultValuesMX,
    mode: 'all',
  });

  const { isValid } = methods.formState;

  const handleOpenApproveModal = () => {
    dispatch(setShowModal(ReviewOption.APPROVE));
  };

  const onSubmit = (data: any) => {
    if (!taskData) return;

    mutateApproveMX({
      agent: taskData.agent,
      nid: taskData.nid,
      id: taskData.id,
      country: country,
      status: 'approved',
      task_result: {
        comment: data.comment,
        complex: data.complex,
        admin_price: Number(data.adminPrice),
        property_type: data.propertyType,
        median_zone: data.medianZone,
        zip_code: data.zipCode,
        property_document_type: data.documentType,
        committee_price_area: Number(data.committeePriceArea),
        committee_land_area: Number(data.committeeLandArea),
        rooms: Number(data.rooms),
        baths: Number(data.baths),
        floor: Number(data.floor),
        elevator: Number(data.elevator),
        has_garage_doc: Boolean(data.hasGarageDoc),
        garage: Number(data.garage),
        years_old: Number(data.yearsOld),
      },
    });
  };

  useHandleMutationToast(isError, isSuccess);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div
          className="d-flex flex-column gap-2 pt-1 pb-2 px-4 overflow-scroll"
          style={{
            height: 'calc(100vh - 333px)',
          }}
        >
          <FieldsMX />
        </div>
        <FormActions
          buttonLabel="Aprobar"
          className="px-4 pt-3"
          onClick={handleOpenApproveModal}
          buttonDisabled={!isValid}
        />
        <ModalForm
          disabled={isLoading}
          onSave={methods.handleSubmit(onSubmit)}
          option={ReviewOption.APPROVE}
          show={showModal === ReviewOption.APPROVE}
          register={methods.register}
        />
      </form>
      <AbortTaskModal />
    </FormProvider>
  );
};
