// libraries.
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

// components.
import { InputField } from '@shared/InputField/InputField';
import { NumericField } from '@shared/NumericField/NumericField';
import { DropdownField } from '@shared/DropdownField/DropdownField';
import { PriceInputField } from '@shared/PriceInputField/PriceInputField';

// utils.
import { DROPDOWN_OPTIONS } from '../../utils';

export const FieldsMX = () => {
  const { watch, unregister } = useFormContext();

  const hasGarageDoc = watch('hasGarageDoc');
  const isCondominium = Number(watch('propertyType')) === 3;

  useEffect(() => {
    if (!hasGarageDoc) {
      unregister('garage');
    }
  }, [hasGarageDoc, unregister]);

  return (
    <>
      <InputField
        label="Conjunto*"
        name="complex"
        placeholder="Nombre del conjunto"
        required
      />
      <div className="d-flex gap-2">
        <DropdownField
          label="Tipo de Inmueble*"
          name="propertyType"
          options={DROPDOWN_OPTIONS.PROPERTY_TYPE}
          required
        />
        {isCondominium && (
          <PriceInputField label="Valor admin." name="adminPrice" required />
        )}
      </div>
      <div className="d-flex gap-2">
        <InputField
          label="Zona mediana*"
          name="medianZone"
          placeholder="Escribir"
          required
        />
        <InputField
          label="Código postal*"
          name="zipCode"
          placeholder="Escribir"
          required
        />
      </div>
      <DropdownField
        label="Tipo de documento para determinar el área*"
        name="documentType"
        options={DROPDOWN_OPTIONS.PROPERTY_DOCUMENT_TYPE_OPTIONS}
        required
      />
      <div className="d-flex gap-2">
        <InputField
          label="Área precio comité*"
          maxLength={3}
          name="committeePriceArea"
          pattern={/^\d{1,3}$/}
          placeholder="Área"
          subLabel="m²"
          required
        />
        <InputField
          label="Área terreno comité*"
          maxLength={3}
          name="committeeLandArea"
          pattern={/^\d{1,3}$/}
          placeholder="Área"
          subLabel="m²"
          required
        />
      </div>
      <div className="d-flex gap-2">
        <NumericField
          label="Recamaras*"
          name="rooms"
          placeholder="Recamaras"
          required
        />
        <NumericField
          label="Baños*"
          name="baths"
          placeholder="Baños"
          required
        />
      </div>
      <div className="d-flex gap-2">
        <NumericField label="Piso*" name="floor" placeholder="Piso" required />
        <NumericField
          label="Elevador*"
          name="elevator"
          placeholder="Elevador"
          required
        />
      </div>
      <div className="d-flex gap-2">
        <DropdownField
          label="Documento estacio.*"
          name="hasGarageDoc"
          options={DROPDOWN_OPTIONS.YES_NO}
          required
        />
        {hasGarageDoc === 1 && (
          <NumericField
            label="Estacionamiento*"
            name="garage"
            placeholder="Estacionamiento"
            required
          />
        )}
      </div>
      <InputField
        label="Antigüedad*"
        maxLength={3}
        name="yearsOld"
        pattern={/^\d{1,3}$/}
        placeholder="Antigüedad"
        subLabel="años"
        required
      />
    </>
  );
};
