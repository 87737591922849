// libraries.
import { useSelector } from 'react-redux';

// selector.
import { CountryCode } from '@domains/country';
import { getSelectedTask } from '@domains/documents-review/store/slices/document-review-slice';

// slice.
import { useGetTaskDetailQuery } from '@domains/documents-review/store/api-slices/task-detail.endpoints';

const CUSTOM_FIELDS_FOR_SELLERS_CO =
  'agente_pricing,nombre_del_conjunto,torre,apartamento,tipo_de_garaje,numero_parqueaderos';

export const DataSellersCO = () => {
  const detailTask = useSelector(getSelectedTask);

  const { data, isFetching } = useGetTaskDetailQuery({
    country: CountryCode.CO,
    nid: detailTask?.nid,
    customFields: CUSTOM_FIELDS_FOR_SELLERS_CO,
  });

  const NO_VALUE = 'No tiene';
  const address = data?.direccion || NO_VALUE;
  const complexName = data?.nombre_del_conjunto || NO_VALUE;
  const tower = data?.torre || NO_VALUE;
  const apartment = data?.apartamento || NO_VALUE;
  const garage = data?.tipo_de_garaje || NO_VALUE;
  const numGarajes = data?.numero_parqueaderos || NO_VALUE;

  if (isFetching) return <div>Cargando información...</div>;

  return (
    <>
      <span>|</span>
      <div>Dirección: {address}</div>
      <span>|</span>
      <div>Conjunto: {complexName}</div>
      <span>|</span>
      <div>
        Torre: {tower}, Apartamento: {apartment}
      </div>
      <span>|</span>
      <div>Garaje: {garage}</div>
      <span>|</span>
      <div>Num. de garajes: {numGarajes}</div>
    </>
  );
};
