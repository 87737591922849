// Libraries.
import { Container } from 'react-bootstrap';

// components.
import { DocumentsTitleBar } from './components/DocumentsTitleBar';

export const DocumentsActionsBar = () => {
  return (
    <Container
      fluid
      className="p-4 pb-3 d-flex justify-content-between align-items-center"
      style={{ borderBottom: '2px solid rgba(0, 0, 0, 0.16)' }}
    >
      <DocumentsTitleBar />
    </Container>
  );
};
