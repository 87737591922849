import { useSelector } from 'react-redux';
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';
import { CountryCode } from '@domains/country';
import React from 'react';

export type ComponentMapping<TProps = {}> = Partial<
  Record<CountryCode, React.ComponentType<TProps>>
>;

export const useCountryComponent = <TProps,>(
  mapping: ComponentMapping<TProps>
): React.ComponentType<TProps> | null => {
  const countryCode = useSelector(getCountry) as CountryCode;
  return mapping[countryCode] || null;
};
