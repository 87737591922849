// components.
import { BreadCrumbs } from './components/BreadCrumbs';

// assets.
import { IconDocumentTool } from '@assets/icons';

// styles.
import { WrapperTaskDetail } from '../styles';
import { TaskDetail } from './components/TaskDetail/TaskDetail';

export const TaskReview = () => {
  return (
    <WrapperTaskDetail>
      <BreadCrumbs />
      <div className="d-flex align-items-center gap-3">
        <div className="icon-detail">
          <IconDocumentTool fill="rgba(176, 103, 255, 1)" />
        </div>
        <TaskDetail />
      </div>
    </WrapperTaskDetail>
  );
};
