// libraries.
import { useSelector } from 'react-redux';
import { robotoRegular, scarpaGray500 } from '@habitech/shared';

// components.
import { ReviewFormCheck } from './ReviewFormCheck';
import { ReviewAgentValidation } from './ReviewAgentValidation';

// selectors.
import { getEmail } from '@domains/user/store/slices/user-slice/user-slice';
import { getSelectedTask } from '@domains/documents-review/store/slices/document-review-slice';

// assets.
import { ImageConfused } from '@assets/images';

export const ReviewContainer = () => {
  const taskDetail = useSelector(getSelectedTask);
  const email = useSelector(getEmail);

  const isDifferentAgent = taskDetail?.agent && taskDetail.agent !== email;

  if (isDifferentAgent) {
    return <ReviewAgentValidation taskDetail={taskDetail} />;
  }
  return (
    <div className="d-flex flex-column align-items-center gap-3 p-4">
      <ImageConfused height={150} width={140} />
      <p
        style={{
          fontSize: '14px',
          fontFamily: robotoRegular,
          color: scarpaGray500,
        }}
      >
        ¿Los documentos corresponden al NID y cumplen con los requisitos
        (incluyen fotos, videos y los datos necesarios)?
      </p>
      <ReviewFormCheck />
    </div>
  );
};
