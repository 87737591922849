// libraries.
import { useFormContext } from 'react-hook-form';

// styles.
import { WrapperInputField } from '@shared/InputField/styles';

interface DropdownFieldProps {
  label: string;
  name: string;
  options: { value: number | string; label: string }[];
  required?: boolean;
}

export const DropdownField = ({
  label,
  name,
  options,
  required = false,
}: DropdownFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const validationRules = {
    required: required ? 'Campo requerido' : false,
    setValueAs: (value: string | number) =>
      value !== '' && !isNaN(Number(value)) ? Number(value) : value,
  };

  return (
    <WrapperInputField>
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
      <select
        id={name}
        {...register(name, validationRules)}
        className="form-select"
      >
        <option value="">Seleccione una opción</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="d-flex justify-content-center">
        {errors[name] && (
          <small className="text-danger my-1 fw-bold">
            {errors[name]?.message as string}
          </small>
        )}
      </div>
    </WrapperInputField>
  );
};
