// components.
import { AbortTaskModal } from '../FormActions/AbortTaskModal';

// hooks.
import { useAbortTask } from './hooks/useAbortTask';

export const AbortTask = () => {
  const { handleOpenAbandonModal } = useAbortTask();

  return (
    <>
      <div
        className="text-danger fw-bold"
        onClick={handleOpenAbandonModal}
        role="button"
      >
        Quitar asignación
      </div>
      <AbortTaskModal />
    </>
  );
};
