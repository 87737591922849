// libraries.
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';

// selectors.
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';
import { useGetListTasksQuery } from '@domains/documents-review/store/api-slices/list-tasks.endpoints';

// components.
import { TaskAction } from './TaskAction';
import { PendingTasks } from './PendingTasks';

// styles.
import { WrapperTable } from './styles';
import { BusinessType } from './components/BusinessType/BusinessType';

export const DocumentsTable = () => {
  const country = useSelector(getCountry);

  const { data, isFetching } = useGetListTasksQuery(
    {
      country: country,
      status: 'pending,progress',
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (isFetching) {
      toast.loading('Cargando tareas...', {
        toastId: 'loadingTasks',
      });
    } else {
      toast.dismiss('loadingTasks');
    }
  }, [isFetching]);

  if (!data) {
    return;
  }

  return (
    <WrapperTable>
      <PendingTasks tasks={data} />
      <Table bordered hover>
        <thead>
          <tr className="text-center headers">
            <th>Fecha y hora</th>
            <th>NID</th>
            <th>Tipo de negocio</th>
            <th>Tipo de tarea</th>
            <th>Agente</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="align-middle text-center rows">
          {data.map(row => (
            <tr key={row.nid}>
              <td>{row.date}</td>
              <td>{row.nid}</td>
              <td>
                <BusinessType businessType={row.business_type} />
              </td>
              <td>{row.task_type}</td>
              <td>{row.agent || 'Sin asignar'}</td>
              <td>
                <TaskAction action={row.action} nid={row.nid} task={row} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </WrapperTable>
  );
};
