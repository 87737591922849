// pages.
import { LoginPage } from '@pages';
import { HomePage } from '@pages/HomePage/HomePage';
import { PolygonsPage } from '@pages/PolygonPage';
import { PolygonViewerPage } from '@pages/PolygonViewer';
import { DocumentAuditsPage } from '@pages/DocumentAuditPage';

// routes.
import { routesPolygonsPage } from '@pages/PolygonPage/routes/routes';
import { routesPolygonViewerPages } from '@pages/PolygonViewer/routes/routes';
import { routesDocumentAuditPage } from '@pages/DocumentAuditPage/routes/routes';
import { routesOrchestratorPricingCommitteePage } from '@pages/OrchestratorPricingCommitteePage/routes/routes';
import { OrchestratorPricingCommitteePage } from '@pages/OrchestratorPricingCommitteePage';

// interfaces.
export interface IRoute {
  id: string;
  path: string;
  element: JSX.Element;
  children?: IRoute[];
  protected?: boolean;
}

export const routes: IRoute[] = [
  {
    id: 'login',
    path: '/',
    element: <LoginPage />,
    protected: false,
  },
  {
    id: 'home',
    path: 'home',
    element: <HomePage />,
    children: [],
    protected: true,
  },
  {
    children: routesPolygonsPage,
    id: 'polygons',
    path: 'polygons',
    element: <PolygonsPage />,
    protected: true,
  },
  {
    children: routesPolygonViewerPages,
    id: 'polygon-viewer',
    path: 'polygon-viewer',
    element: <PolygonViewerPage />,
    protected: true,
  },
  {
    children: routesDocumentAuditPage,
    id: 'document-audit',
    path: 'document-audit',
    element: <DocumentAuditsPage />,
    protected: true,
  },
  {
    children: routesOrchestratorPricingCommitteePage,
    id: 'orchestrator-pricing-committee',
    path: 'orchestrator-pricing-committee',
    element: <OrchestratorPricingCommitteePage />,
    protected: true,
  },
];
