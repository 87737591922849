// libraries.
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// slices.
import { setDefaultState } from '@domains/documents-review/store/slices/document-review-slice';

// assets.
import { IconArrow } from '@assets/icons';

export const BreadCrumbs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/document-audit/tasks');
    dispatch(setDefaultState());
  };

  return (
    <div className="bread-crumbs">
      <div
        className="d-flex gap-2 align-items-center arrow"
        onClick={handleClick}
      >
        <div>
          <IconArrow />
        </div>
        <div className="my-task">Mis tareas</div>
      </div>
      <span>|</span>
      <div className="review">Revisión de documento</div>
    </div>
  );
};
