// libraries.
import { Col, Row } from 'react-bootstrap';

// components.
import { TaskReview } from './components/TaskReview/TaskReview';
import { ReviewButtons } from './components/ReviewButtons/ReviewButtons';
import { DocumentReview } from './components/DocumentReview/DocumentReview';

// shared
import { Layout } from '@shared';

export const DocumentTaskDetail = () => {
  return (
    <Layout>
      <div className="h-100 w-100">
        <TaskReview />
        <Row
          className="m-0"
          style={{ height: 'calc(100% - 134px)', width: '100%' }}
        >
          <Col
            lg={9}
            className="p-0"
            style={{ background: 'rgba(243, 243, 243, 1)' }}
          >
            <ReviewButtons />
          </Col>
          <Col
            lg={3}
            className="p-0"
            style={{ background: 'rgba(250, 250, 250, 1)' }}
          >
            <DocumentReview />
          </Col>
        </Row>
      </div>
    </Layout>
  );
};
